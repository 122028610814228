.main {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .main_container {
        margin: 80px 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        .left_side_menu {
            display: flex;
            flex-direction: column;
            .menu_list {
                position: sticky;
                top: 100px;
                font-size: 15px;
                display: flex;
                flex-direction: column;
                gap: 7px;
                .menu_item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 7px 10px;

                    border-radius: 5px;
                    transition: background-color 0.2s;
                    cursor: pointer;
                    &:hover {
                        background: rgba(0, 0, 0, 0.1);
                    }
                    .menu_item_icon {
                        height: 25px;
                        display: flex;
                        align-items: center;
                        svg {
                            width: 25px;
                            height: auto;
                            aspect-ratio: 1/1;
                        }
                    }
                    .menu_item_text {
                        margin-left: 10px;
                    }
                }
                .active_menu_item {
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                }
            }
        }
        .active_block {
            width: 700px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .settings {
            width: 400px;
            gap: 12px;
            display: flex;
            flex-direction: column;
            .settings_item {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 100%;
            }
            .submit {
                width: 100%;
            }
        }
    }
}