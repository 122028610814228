.user-selector {
  padding: 5px 10px;
  border-radius: 7px;
  transition: .3s;
  cursor: pointer;
  border: 2px rgb(229, 231, 235) dashed;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .user-selector-title {
    font-weight: 300;
    font-size: 13px;
  }
  .user-selector-main1 {
    .selected-user1 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
    }
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 15px;
      font-weight: 400;
    }
    p {
      margin: auto 0;
      height: fit-content;
    }
  }
}