.input-contain{
  position: relative;
  .react-datepicker-wrapper {
    width: 100%;
  }
  input{
    height: 40px;
    width: 100%;
    border: 1px solid rgb(229, 231, 235);
    border-radius: 7px;
    padding: 0 15px;
    font-size: 15px;
  }
  input:focus{
    outline: none;
    border: 2px solid blueviolet;
  }
  input:focus + .placeholder-text .text, :not(input[value=""]) + .placeholder-text .text{
    background-color: white;
    font-size: 15px;
    color: black;
    transform: translate(0, -85%);
  }
  input:focus + .placeholder-text .text{
    border-color: blueviolet;
    color: blueviolet;
  }
  .placeholder-text{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 3px solid transparent;
    background-color: transparent;
    pointer-events: none;
    display: flex;
    align-items: center;

    .text{
      margin: 0 10px;
      padding: 0 5px;
      font-size: 15px;
      background-color: transparent;
      transform: translate(0);
      color: black;
      transition: transform 0.15s ease-out, font-size 0.15s ease-out, background-color 0.2s ease-out, color 0.15s ease-out;
    }
  }
  input, .placeholder-text{
  }
}
