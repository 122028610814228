header {
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    width: 100%;
    height: 80px;
    overflow: hidden;
    display: flex;
    padding: 10px 20px;
    font-size: 15px;
    .logo_and_name {
        width: inherit;
        display: flex;
        align-items: center;
        img {
            height: 100%;
            width: auto;
        }
    }
    .right-elements {
        width: 100%;
        text-align: right;
        &:before {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            content: "";
        }
        button {
            border: 0;
            border-radius: 7px;
            height: 80%;
            vertical-align: middle;
            margin-left: 15px;
            font-weight: 400;
            padding-left: 20px;
            padding-right: 20px;
        }
        .help-button {
            background-color: #fff0f0;
            transition: .3s;
            &:hover {
                background-color: #fcdfdf;
                cursor: pointer;
            }
        }
        .support-button {
            background-color: #f0fff0;
            transition: .3s;
            &:hover {
                background-color: #dcfcdc;
                cursor: pointer;
            }
        }
        .wf-logo {
            border-radius: 7px;
            height: 100%;
            background-color: white;
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
            cursor: pointer;
            transition: .3s;
            &:hover {
                opacity: 50%;
            }
            img {
                height: 100%;
                width: auto;
            }
        }
        .settings {
            &:before {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                content: "";
            }
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
            height: 80%;
            svg {
                vertical-align: middle;
                height: 60%;
                width: auto;
            }
        }
    }
}